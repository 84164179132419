import React, { FC, useState, useEffect, useCallback, useRef, useContext } from 'react';
import Carousel from 'react-multi-carousel';
import classnames from 'classnames';
import 'react-multi-carousel/lib/styles.css';

import NextArrow from 'components/ControlledCarousel/subcomponents/NextArrow';
import PrevArrow from 'components/ControlledCarousel/subcomponents/PrevArrow';
import CustomDots from 'components/ControlledCarousel/subcomponents/CustomDots';
import { parseBoolean } from 'utils/parseHelpers';
import CustomDot from './CustomDots';
import { CommonSettingsContext } from '../Layout';

import { ControlledCarouselProps, CarouselItem, INavigatorButtons, NavigatorProps } from './model';
import './ControlledCarousel.scss';

const ControlledCarousel: FC<ControlledCarouselProps> = ({
  children,
  classes = '',
  showDots = false,
  showNavigators = false,
  navigatorsOutside = false,
  dotsOutside = false,
  responsiveObj,
  customDots,
  showAllDots,
  ...restProps
}) => {
  const commonSettings = useContext(CommonSettingsContext);
  const waiSettings = commonSettings?.blocks?.find((block) => block.structure === 'WAI');
  const isRTL = parseBoolean(commonSettings?.isRTL);
  const carouselCSSClassNames = classnames(classes, {
    'is-rtl': isRTL,
  });

  const [activeSlide, setActiveSlide] = useState(0);
  const [width, setWidth] = useState(0);
  const [isLessContent, setIsLessContent] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState<number | undefined>(undefined);
  const [navigatorProps, setNavigatorProps] = useState<NavigatorProps>({
    controller: null,
    allItemsCount: 0,
    activeSlide: 0,
  });
  const thisCarousel = useRef<CarouselItem | any>(null);
  const controllerProps = navigatorProps.controller?.props;
  const controllerState = navigatorProps.controller?.state;
  const childrenToRender = children.filter((child) => child);
  const hasGutter =
    controllerProps?.responsive[controllerState.deviceType]?.partialVisibilityGutter;
  const setActiveSlideHandler = useCallback(
    (index: number) => {
      setActiveSlide(index);
    },
    [activeSlide]
  );

  const carouselHandleResize = () =>
    setIsLessContent(slidesToShow === undefined || childrenToRender?.length <= slidesToShow);

  const updateWidth = (e) => {
    setWidth(e.target?.innerWidth);
    carouselHandleResize();
  };

  useEffect(() => {
    carouselHandleResize();
    setNavigatorProps({
      controller: thisCarousel.current,
      allItemsCount: childrenToRender?.length,
      activeSlide,
    });
  }, [width]);

  useEffect(() => {
    childrenToRender?.length > 0 && updateWidth(thisCarousel);
    setSlidesToShow(thisCarousel.current?.state.slidesToShow);
  }, [navigatorProps]);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (isRTL && childrenToRender?.length && thisCarousel?.current?.state?.slidesToShow) {
      thisCarousel.current.goToSlide(
        childrenToRender.length > thisCarousel.current.state.slidesToShow
          ? childrenToRender.length - thisCarousel.current.state.slidesToShow
          : 0
      );
    }
  }, [thisCarousel.current, isRTL]);

  const NavigatorButtons: FC<INavigatorButtons> = ({ next, previous }) =>
    !isLessContent && showNavigators && previous !== undefined && next !== undefined ? (
      <div className="carousel__navigators">
        <PrevArrow {...navigatorProps} ariaLabel={waiSettings?.properties.prevAria} />
        <NextArrow {...navigatorProps} ariaLabel={waiSettings?.properties.nextAria} />
      </div>
    ) : null;

  return (
    <>
      {childrenToRender?.length > 0 ? (
        <div className={carouselCSSClassNames}>
          <Carousel
            ssr
            showDots={showDots && !isLessContent && !showAllDots}
            renderDotsOutside={dotsOutside}
            renderButtonGroupOutside={navigatorsOutside}
            centerMode={isLessContent && childrenToRender?.length !== 1 && !hasGutter}
            partialVisible={!isLessContent && childrenToRender?.length > 1 && hasGutter}
            beforeChange={setActiveSlideHandler}
            className="carousel"
            responsive={responsiveObj}
            arrows={false}
            itemClass={`${classes}__item`}
            ref={thisCarousel}
            customButtonGroup={<NavigatorButtons />}
            customDot={customDots ? <CustomDot customDots={customDots} /> : null}
            {...restProps}
          >
            {childrenToRender}
          </Carousel>
        </div>
      ) : null}
      {customDots && showAllDots && showDots ? (
        <CustomDots
          customDots={customDots}
          activeSlide={activeSlide}
          onClick={thisCarousel?.current?.goToSlide}
        />
      ) : null}
    </>
  );
};
export default ControlledCarousel;
